import React from "react"
import { IMG } from "../const"

export const TITLES = {
  services: {
    title: (
      <>
        <strong>insurance</strong> <br /> technology solutions
      </>
    ),
    strongText:
      "We offer a wide range of digital services for insurance companies",
    uppercase: (
      <>
        learn about
        <br /> our services
      </>
    ),
    withIndent: false,
  },
  testimonials: {
    title: (
      <>
        clients’ <strong>testimonials</strong>
      </>
    ),
    strongText:
      "Our clients often come to us through recommendations, which is a good indicator of our services",
    uppercase: (
      <>
        Trusted
        <br /> partnership
      </>
    ),
    withIndent: false,
  },
  integrations: {
    title: (
      <>
        list of <br />
        <strong>insurtech integrations</strong> we can implement
      </>
    ),
    strongText:
      "we have extensive experience integrating different insurtech products, including health, home, auto, commercial, cyber, and b2b",
    uppercase: "integrations",
    withIndent: true,
  },
  technology: {
    title: (
      <>
        LaSoft <br />
        <strong>one–stop shop</strong> <br />
        technology partner:
      </>
    ),
    strongText:
      "working with us our clients receive a full range of technology services",
    uppercase: "full range of services",
    withIndent: true,
  },
  colored: {
    title: (
      <>
        interested in
        <br /> insurtech software
        <br /> development?
      </>
    ),
    strongText:
      "request a consultation so that we can help build your insurtech product",
    uppercase: "Project estimate",
    link: "#contact-form",
    linkClassName: "insuretech-estimate",
    imageDesktop: `${IMG.INSURTECH_SOFTWARE}/scheme-white.svg`,
    imageDesktopWidth: 918,
    imageDesktopHeight: 92,
    withIndent: false,
    industry: "insurtech",
  },
}

export const SERVICES = {
  text: "",
  items: [
    {
      id: 1,
      title: "InsurTech Software Development",
      text: (
        <>
          <p>
            When you partner with an insurtech software development company, you
            get high-quality software that solves your specific goal on time.
            We'll help you with the following:
          </p>
          <ul>
            <li>Custom insurance software with third parties integrations;</li>
            <li>
              Adapting low‑code software solutions for startups in the insurance
              industry field;
            </li>
            <li>Business process automatization software;</li>
            <li>Legacy system re-engineering;</li>
            <li>Underwriting process automatization.</li>
          </ul>
        </>
      ),
    },
    {
      id: 2,
      title: "Insurance Mobile App Development",
      text:
        "We help insurance companies and startups better serve their customers by developing beautiful and intuitive mobile apps. Android or iOS app development? You don't have to choose. We make solutions for all operating systems.",
    },
    {
      id: 3,
      title: "Insurance Product Development",
      text: (
        <>
          <p>
            Users expect the insurance product will be intuitive, user-friendly
            and include only the necessary features. Achieve this goal with the
            help of design services:
          </p>
          <ul>
            <li>Product discovery;</li>
            <li>Prototyping;</li>
            <li>UX/UI design;</li>
            <li>Advanced data visualization.</li>
          </ul>
        </>
      ),
    },
    {
      id: 4,
      title: "Data Analytics and Visualization",
      text: (
        <>
          <p>
            Make the right decisions easier through advanced dashboards. We
            offer a comprehensive list of insurance data analytics and
            visualization services:
          </p>
          <ul>
            <li>Data extraction, ingestion, and cleansing;</li>
            <li>Data governance and management;</li>
            <li>Visual analytics;</li>
            <li>Predictive analytics and machine learning.</li>
          </ul>
        </>
      ),
    },
    {
      id: 5,
      title: "Dedicated InsurTech Developers",
      text: (
        <>
          <p>
            The development of insurtech solutions usually takes months.
            Strengthen your technical team with our high-quality dedicated teams
            or individual dedicated employees. We use a structured and tested
            approach to build a professional, long-term dedicated team for our
            partners. Our project managers fully cover the administrative part
            of the dedicated insurance product development. So you can focus
            only on essential workflows!
          </p>
          <ul>
            <li>Python dedicated development team;</li>
            <li>React/React JS dedicated development team;</li>
            <li>Php dedicated development team;</li>
            <li>Ruby on Rails dedicated development team.</li>
          </ul>
        </>
      ),
    },
  ],
}

export const INTEGRATIONS = [
  { name: "Joshue", icon: "joshue.png" },
  { name: "Applied Epic", icon: "applied-epic.png" },
  { name: "ivari", icon: "ivari.svg" },
  { name: "PayPolicy", icon: "pay-policy.svg" },
  { name: "Ascend", icon: "ascend.svg" },
  { name: "Bestow", icon: "bestow.png" },
  { name: "Cover Wallet", icon: "cover-wallet.png" },
  { name: "Zego", icon: "zego.png" },
  { name: "Boost", icon: "boost.png" },
  { name: "Coterie Insurance", icon: "coterie-insurance.png" },
  { name: "Assurance", icon: "assurance.svg" },
]
